<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <!-- <v-select
        v-model="selectedStores"
        :items="availableStores"
        item-text="value"
        item-value="key"
        label="Select Item"
        multiple
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @mousedown.prevent @click="toggle">
            <v-list-item-action>
              <v-icon>
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Select All </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item.value }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text text-caption">
            (+{{ selectedStores.length - 1 }} others)
          </span>
        </template>
      </v-select> -->
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col sm="4" cols="12">
                  <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                    發行點數設定
                  </h4>
                </v-col>
                <v-col class="text-right" v-if="canEdit">
                  <v-btn
                    class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-secondary
                      py-3
                      px-6
                      ms-3
                    "
                    @click="editPointSettings(null)"
                    >{{ $t(`points["Add Point Settings"]`) }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-row>
            <v-col
              sm="6"
              md="4"
              cols="12"
              v-for="item in pointSettings"
              :key="item.id"
            >
              <v-card class="mb-6 card-shadow border-radius-xl py-4">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-default border-radius-xl mt-n8"
                      class="shadow-dark"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >fa-coins</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <h4 class="text-h5 text-typo font-weight-bolder mb-0">
                      {{ item.name }}
                    </h4>
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      {{ moment.unix(item.updatedAt).format("MM/DD/YYYY") }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-4">
                  <v-col sm="12" class="">
                    <v-tooltip bottom v-if="canEdit">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          dark
                          small
                          color="cyan"
                          v-on="on"
                          @click="editPointSettings(item)"
                        >
                          <v-icon>fas fa-edit</v-icon>
                          編輯點數設定
                        </v-btn>
                      </template>
                      <span>編輯點數設定</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          dark
                          small
                          color="blue darken-2"
                          v-on="on"
                          @click="gotoPeriodsPage(item.id)"
                        >
                          <v-icon>fas fa-eye</v-icon>
                          查看期別設定
                        </v-btn>
                      </template>
                      <span>查看期別設定</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <hr class="dark horizontal mt-3 mb-4" />
                <v-row class="px-4">
                  <v-col cols="12">
                    <p class="mb-0 text-body">
                      <span class="font-weight-light ms-1">綁定信託帳戶：</span>
                      <span class="text-success text-sm font-weight-bolder">{{
                        item.isBindTrustAccount
                          ? $t(`common["Yes"]`)
                          : $t(`common["No"]`)
                      }}</span>
                    </p>
                    <!-- <p class="mb-0 text-body">
                      <span class="font-weight-light ms-1">支援預算轉讓：</span>
                      <span class="text-success text-sm font-weight-bolder">{{
                        item.isSupportBudgetTransfer
                          ? $t(`common["Yes"]`)
                          : $t(`common["No"]`)
                      }}</span>
                    </p>
                    <p class="mb-0 text-body">
                      <span class="font-weight-light ms-1">支援點數轉贈：</span>
                      <span class="text-success text-sm font-weight-bolder">{{
                        item.isSupportMemberTransfer
                          ? $t(`common["Yes"]`)
                          : $t(`common["No"]`)
                      }}</span>
                    </p> -->
                    <p class="mb-0 text-body">
                      <span class="font-weight-light ms-1">可使用特店數：</span>
                      <span class="text-success text-sm font-weight-bolder">{{
                        item.stores.length
                      }}</span>
                      <span class="px-5">
                        <!-- <v-btn
                          icon
                          x-small
                          raised
                          @click="
                            stores = item.stores;
                            openStoresDialog = true;
                          "
                        >
                          <v-icon>fa-solid fa-list</v-icon>
                        </v-btn> -->
                        <v-btn
                          class="mx-2"
                          dark
                          small
                          color="pink darken-2"
                          @click="
                            stores = item.stores;
                            openStoresDialog = true;
                          "
                        >
                          <v-icon>fas fa-list</v-icon>
                          查看特店資訊
                        </v-btn>
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="openStoresDialog" max-width="800px">
        <v-card>
          <v-card-title>
            特店列表
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchStore"
              append-icon="fa-search"
              :label="$t(`common['Search']`)"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="tableStoreHeaders"
              :items="stores"
              :search="searchStore"
              :sort-by="['id']"
              :sort-desc="[false]"
            >
              <!-- <template v-slot:no-data> NO DATA HERE! </template> -->
              <template v-slot:item.status="{ item }">
                <span>{{ item.status ? "啟用" : "停用" }}</span>
              </template>
              <template v-slot:item.updatedAt="{ item }">
                <span>{{
                  item.updatedAt
                    ? moment.unix(item.updatedAt).format("MM/DD/YYYY")
                    : ""
                }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openPointDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ isNew ? $t(`common["Add"]`) : $t(`common["Edit"]`) }}
            </span>
            <span class="text-h5">
              {{ $t(`points["Settings"]`) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="pointForm" v-model="pointFormValid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editPoint.name"
                      :label="$t(`points['Name']`)"
                      :rules="rules.name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-switch
                      v-model="editPoint.isBindTrustAccount"
                      inset
                      color="green"
                      :false-value="0"
                      :true-value="1"
                      label="綁定信託帳戶"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editPoint.stores"
                      :items="availableStores"
                      item-text="storeName"
                      item-value="id"
                      :label="$t(`views['Select Store']`)"
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          v-if="availableStores.length"
                          ripple
                          @mousedown.prevent
                          @click="toggle"
                        >
                          <v-list-item-action>
                            <v-icon>
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t(`views['Select All']`) }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.storeName }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ editPoint.stores.length - 1 }} others)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSaveEditPoint">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { fetchPointSettings, fetchStores } from "src/apis/fetchData";
import { updatePointSetting } from "src/apis/updateData";
import { checkPermission } from "src/util/utils";

export default {
  name: "PointSettings",
  components: {},
  data() {
    return {
      moment: moment,
      isNew: false,
      pointFormValid: true,
      openStoresDialog: false,
      openPointDialog: false,
      tableStoreHeaders: [
        {
          text: "SN",
          sortable: true,
          value: "id",
        },
        {
          text: "類型",
          sortable: true,
          value: "categoryName",
        },
        {
          text: "名稱",
          sortable: true,
          value: "name",
        },
        {
          text: "狀態",
          sortable: true,
          filterable: false,
          value: "status",
        },
        {
          text: "營業地址",
          sortable: false,
          value: "address",
        },
        {
          text: "營業電話",
          sortable: false,
          value: "phone",
        },
        {
          text: "更新時間",
          sortable: true,
          filterable: false,
          value: "updatedAt",
        },
      ],
      searchStore: null,
      stores: [],
      pointSettings: [],
      defaultPoint: {
        name: null,
        isBindTrustAccount: 1,
        stores: [],
      },
      editPoint: {
        name: null,
        isBindTrustAccount: 1,
        stores: [],
      },
      availableStores: [],
      rules: {
        name: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      // availableStores: [
      //   {
      //     key: 1,
      //     value: "foo",
      //   },
      //   {
      //     key: 2,
      //     value: "bar",
      //   },
      //   {
      //     key: 3,
      //     value: "fizz",
      //   },
      //   {
      //     key: 4,
      //     value: "buzz",
      //   },
      //   { key: 5, value: "fizzbuzz" },
      //   { key: 6, value: "foobar" },
      // ],
      // selectedStores: [],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
    }),
    selectAllStores() {
      return (
        this.editPoint &&
        this.editPoint.stores &&
        this.editPoint.stores.length === this.availableStores.length
      );
    },
    selectSomeStores() {
      return (
        this.editPoint &&
        this.editPoint.stores &&
        this.editPoint.stores.length > 0 &&
        !this.selectAllStores
      );
    },
    icon() {
      if (this.selectAllStores) return "fas fa-solid fa-check-square";
      if (this.selectSomeStores) return "fas fa-solid fa-minus-square";
      return "far fa-light fa-square";
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:PointManage",
          "EditPointSettings",
          true
        );
      }
      return false;
    },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.getPointSettings();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getPointSettings();
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllStores) {
          this.editPoint.stores = [];
        } else {
          // this.editPoint.stores = this.availableStores.slice();
          this.editPoint.stores = this.availableStores.map((obj) => obj.id);
        }
      });
    },
    gotoPeriodsPage(pointId) {
      this.$router.push({ name: "Period Management", query: { pointId } });
    },
    // getMerchantStores() {
    //   const merchantId = 1;
    //   fetchStores(merchantId)
    //     .then((res) => {
    //       console.log("fetchStores done", res);
    //       this.availableStores = res.data;
    //     })
    //     .catch((e) => {
    //       console.log("fetchStores failed", e);
    //     });
    // },
    getPointSettings() {
      const merchantId = this.ssoUser.user._json.groups.merchant;
      fetchPointSettings(merchantId)
        .then((res) => {
          console.log("fetchPointSettings done", res);
          this.pointSettings = res.data;
          this.$store.commit("setPointSettings", res.data);
        })
        .catch((e) => {
          console.log("fetchPointSettings failed", e);
        });
    },
    editPointSettings(item) {
      if (item) {
        this.editPoint = Object.assign({}, item);
        this.editPoint.stores = this.editPoint.stores.map((obj) => obj.id);
        this.isNew = false;
      } else {
        this.editPoint = Object.assign({}, this.defaultPoint);
        this.isNew = true;
      }
      let query = {
        fulltext: false,
        approved: "approved",
      };
      fetchStores(this.merchantId, query)
        .then((res) => {
          console.log("fetchStores done", res);
          this.availableStores = res.data;
          this.openPointDialog = true;
        })
        .catch((e) => {
          console.log("fetchStores failed", e);
        });
      // this.openPointDialog = true;
    },
    closeEditDialog() {
      this.$refs.pointForm.resetValidation();
      this.$refs.pointForm.reset();
      this.openPointDialog = false;
    },
    onSaveEditPoint() {
      console.log(this.editPoint);
      // const merchantId = 1;
      const valid = this.$refs.pointForm.validate();

      if (valid) {
        updatePointSetting(this.merchantId, this.editPoint)
          .then((res) => {
            console.log("updatePointSetting done", res);
            this.getPointSettings();
            this.openPointDialog = false;
            this.$refs.pointForm.resetValidation();
            this.$refs.pointForm.reset();
          })
          .catch((e) => {
            console.log("updatePointSetting failed", e);
            let errmsg = this.isNew ? "新點數設定失敗" : "更新點數設定失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        text: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
